<template>
  <div class="activity">
    <Baberrage v-if="activity.activityStatus === 0" :activity-id="activity.id" />
    <music-note v-if="activity.bgmBtnSwitch" :mp3-path="activity.bgmPath" :position="activity.bgmBtnPosition" />
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="darkred">
      <van-swipe-item v-for="(item, idx) in coverList" :key="idx">
        <img :src="item" />
      </van-swipe-item>
    </van-swipe>
    <div class="activity_info">
      <div class="title">{{ activity.title }}</div>
      <div class="descn">{{ activity.descn }}</div>
      <div class="price">
        <span class="orig_price">{{ activity.price | formatMoney }}</span>
      </div>
      <div class="item">
        <span class="label">活动时间：</span><span class="value">{{ activity.startTime }} 至 {{ activity.endTime }}</span>
      </div>
      <div class="item">
        <span class="label">剩余时间：</span><span class="value">
          <CountDown v-if="activity.endTime" :end-time="activity.endTime" />
        </span>
      </div>
    </div>

    <template v-if="!joinFlag">
      <!-- 未参与团购时显示 -->
      <div class="teams">
        <div class="tips">
          <ul>
            <li>欢迎参与拼团活动。您可以：</li>
            <li>1. 点击页面底部的“我要开团当团长”发起一个新的拼团活动；</li>
            <li>2. 将本页面分享给微信好友，邀请好友发起拼团;</li>
            <li>3. 点击“生成海报”并分享，邀请好友发起拼团。</li>
          </ul>
        </div>
        <div class="title">现有团队</div>
        <div class="avatar-queue">
          <span v-for="(item, idx) in teamLeaderList" :key="idx" class="avatar">
            <img :src="item" />
          </span>
        </div>
      </div>
    </template>
    <template v-if="joinFlag && repeatOrderFlag">
      <!-- 参与团购，但是可重复下单时显示 -->
      <div class="teams">
        <div class="tips">
          <ul>
            <li>您已经参与本次拼团活动。您可以：</li>
            <li>1. 点击页面底部的“我要开团”，发起新的拼团。</li>
            <li>2. 将本页面分享给微信好友，邀请好友发起新的拼团;</li>
            <li>3. 点击“生成海报”并分享，邀请好友发起新的拼团。</li>
          </ul>
        </div>
        <div class="title">加入的团队</div>
        <div class="avatar-queue">
          <span v-for="(item, idx) in teamLeaderList" :key="idx" class="avatar">
            <img :src="item.wxUser.avatar" />
          </span>
        </div>
      </div>
    </template>
    <div ref="activityContent" class="activity-content" />

    <van-cell-group v-if="formItemList.length > 0" class="ext-data-form-container">
      <div v-for="(item,idx) in formItemList" :key="idx">
        <TextField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :required="item.required" :maxlength="item.maxLength" />
        <MobileField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :required="item.required" />
        <AgeField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :required="item.required" />
        <DatetimePickerField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :required="item.required" />
        <RadioField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :options="item.optionsContent" :required="item.required" />
        <PickerField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :options="item.optionsContent" :required="item.required" />
        <CheckboxField v-model="extDataForm[item.title]" :dtype="item.dataType" :label="item.title" :options="item.optionsContent" :required="item.required" />
      </div>
    </van-cell-group>
    <div class="copyright">--==易办学==--</div>

    <van-goods-action v-if="activity.activityStatus === 0">
      <van-goods-action-button type="primary" text="我要开团当团长" @click="buyNow" />
    </van-goods-action>
    <van-goods-action v-else>
      <van-goods-action-button v-if="activity.activityStatus === -1" type="danger" text="团购未开始" />
      <van-goods-action-button v-if="activity.activityStatus === 1" type="danger" text="团购已结束" />
    </van-goods-action>

    <SharePoster v-if="activity.activityStatus === 0 && activity.shareBtnSwitch && teamId > 0" sharp="square" :aid="activity.id" atype="groupOn"
      :position="activity.shareBtnPosition" />
  </div>
</template>
<script>
import wxconfig from '@/wx'
import groupOnApi from '@/api/group-on'

import wxComPayApi from '@/api/wxcom-pay'

import MusicNote from '@/views/components/MusicNote.vue'
import SharePoster from '@/views/components/SharePoster.vue'
import Baberrage from '@/views/components/Baberrage.vue'
import { isOnline } from '@/utils/comm'

import TextField from '@/views/components/TextField.vue'
import AgeField from '@/views/components/AgeField.vue'
import MobileField from '@/views/components/MobileField.vue'
import RadioField from '@/views/components/RadioField.vue'
import CheckboxField from '@/views/components/CheckboxField.vue'
import PickerField from '@/views/components/PickerField.vue'
import DatetimePickerField from '@/views/components/DatetimePickerField.vue'
import CountDown from '@/views/components/CountDown.vue'

export default {
  components: {
    MusicNote,
    SharePoster,
    MobileField,
    TextField,
    AgeField,
    PickerField,
    RadioField,
    DatetimePickerField,
    CheckboxField,
    Baberrage,
    CountDown
  },
  data() {
    return {
      appId: -2,
      teamId: -1, // 团队ID
      activity: {
        id: '',
        title: '',
        descn: ''
      },
      coverList: [],
      loc: window.location.href,
      initLink: sessionStorage.getItem('initLink'),
      btnBuyingFlag: false, // 已点击购买按钮，正在购买标识
      memberList: [], // 团队成员列表
      curTeam: {},
      formItemList: [],
      extDataForm: {}, // 自定义表单
      joinFlag: false,
      repeatOrderFlag: false,
      teamLeaderFlag: false,
      teamLeaderList: [], // 团队列表,
      refereeUserId: '' // 推荐人的userId,
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.teamId = parseInt(this.$route.params.teamId)
    this.activity.id = parseInt(this.$route.params.id)
    this.refereeUserId = this.$route.query.ruId
    this.loadActivityData()
  },
  mounted() {},
  methods: {
    /* 校验表单信息
    validateExtDataForm() {
      let keyArr = [] // 自定义表单中已填写数据的表单项
      Object.keys(this.extDataForm).forEach(e => {
        console.log(e, '->', this.extDataForm[e], this.extDataForm[e] instanceof String, ', isArray->', this.extDataForm[e] instanceof Array)
        if (this.extDataForm[e] instanceof String && this.extDataForm[e] !== '') {
          // 当表单项的数据为空时，不加入
          keyArr.push(e)
        } else if (this.extDataForm[e] instanceof Array && this.extDataForm[e].length !== 0) {
          // 当表单项的数据为空时，不加入
          keyArr.push(e)
        }
      })
      let formItemIdArr = [] // 后台设置的必填项列表
      this.formItemList.forEach(i => {
        if (i.required) {
          // 必填
          formItemIdArr.push(i.title)
        }
      })

      // 校验字段必填
      for (let i = 0; i < formItemIdArr.length; i++) {
        if (keyArr.indexOf(formItemIdArr[i]) === -1) {
          let formItem = this.getFormItemById(formItemIdArr[i])
          if (formItem.dataType === 'checkbox' || formItem.dataType === 'radio') {
            this.$toast('请选择' + formItem.title)
            return false
          } else {
            this.$toast('请填写' + formItem.title)
            return false
          }
        }
      }
      return true
    },
    getFormItemById(id) {
      for (let i = 0; i < this.formItemList.length; i++) {
        if (this.formItemList[i].title === id) {
          return this.formItemList[i]
        }
      }
    },
    buyNow2() {
      // let validateResult = this.validateExtDataForm()
      let validateResult = true
      if (!validateResult) {
        // 滚动到表单位置
        const formContainer = document.querySelector('.ext-data-form-container')
        formContainer.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
        // document.documentElement.scrollTop = this.$refs.activity.scrollHeight
      } else {
        console.log(JSON.stringify(this.extDataForm))
        this.buyNow()
      }
    },*/
    // 加载团队列表
    loadTeamLeaders() {
      groupOnApi.getTeamLeaders(this.activity.id).then(res => {
        if (res.succ) {
          this.teamLeaderList = res.data.items
        }
      })
    },
    // 加载当前用户参与的团队
    loadMyJoinTeams() {
      groupOnApi.getMyJoinTeams(this.activity.id).then(({ succ, data }) => {
        if (succ) {
          this.teamLeaderList = data.items
        }
      })
    },

    // 加载活动信息
    loadActivityData() {
      groupOnApi.get(this.activity.id, this.teamId).then(({ succ, data }) => {
        if (succ) {
          // 是否参加标识
          this.joinFlag = data.joinFlag
          this.repeatOrderFlag = data.item.repeatOrderSwitch
          // let memberInTeamId = data.teamId
          // 如果可重复购买，不考虑是否已购买，都不跳转
          if (this.repeatOrderFlag) {
            // 渲染页面
            this.randerActivityPage(data)
          } else {
            // 如果不可重复购买
            if (this.joinFlag) {
              // 1.根据joinFlag判断是否已经购买，已购买时则跳转到所在队伍
              let memberInTeamId = data.team.id
              // 说明当前登录用户已经参与过团购,跳转到登录用户参与的团购加入页面
              window.location.href = `/${this.appId}/groupOn/join/${this.activity.id}/${memberInTeamId}`
            } else {
              // 2.未购买，不跳转，继续渲染当前页面
              this.randerActivityPage(data)
            }
          }
        } else {
          this.$toast('活动不存在')
        }
      })
    },
    randerActivityPage(data) {
      this.activity = data.item
      // 初始化轮播图列表
      this.coverList.push(data.item.cover)
      // 活动详情
      this.$refs.activityContent.innerHTML = data.item.content
      if (this.activity.customFormSwitch) {
        // 自定义表单内容
        this.formItemList = data.formItemList
      }
      this.teamLeaderFlag = data.teamLeaderFlag
      if (this.joinFlag) {
        // 列出自己的团队
        this.loadMyJoinTeams()
      } else {
        // 列出全部团队
        if (this.activity.activityStatus === 0) {
          this.loadTeamLeaders()
        } else {
          this.teamLeaderList = [require('@/assets/default_avatar_2.jpg')]
        }
      }
      // 初始化微信相关
      this.initWxConfig()
    },
    // 直接下单逻辑
    buyNow() {
      if (this.btnBuyingFlag) {
        return false
      }
      this.btnBuyingFlag = true
      let data = {
        activityId: this.activity.id,
        p2: this.teamId,
        refereeUserId: this.refereeUserId,
        extFormData: JSON.stringify(this.extDataForm)
      }
      // this.$toast(JSON.stringify(data))
      wxComPayApi.groupOnBuy(data).then(res => {
        // this.$toast(JSON.stringify(res))
        if (res.succ) {
          let data = res.data
          if (data.nextCode !== '00') {
            // 根据nextCode分流
            this.redirectByNextCode(data)
          } else {
            this.$wx.chooseWXPay({
              appId: data.appId,
              timestamp: data.timeStamp,
              nonceStr: data.nonceStr,
              package: data.package,
              signType: data.signType,
              paySign: data.paySign,
              success: res => {
                // 支付成功后跳转，已经被微信“点金计划”阻止，不可自定义支付后跳转
                // this.$router.push(`/groupOnResult/${this.appId}/${this.activity.id}/${data.teamId}`)
              }
            })
          }
          this.btnBuyingFlag = false
        }
      })
    },
    redirectByNextCode(data) {
      let nextCode = data.nextCode
      if (nextCode === '04') {
        // 自定义表单错误，编码：04，使用toast进行提示
        const formContainer = document.querySelector('.ext-data-form-container')
        formContainer.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
        this.$toast(data.msg)
      } else {
        // 其他错误。使用alert进行提示
        this.$dialog
          .alert({
            title: '提示',
            message: data.msg,
            theme: 'round-button'
          })
          .then(() => {
            if (data.url) {
              // 如果有跳转链接，就跳转
              this.$router.push(data.url)
            }
          })
      }
    },
    addViewer() {
      // 添加活动访问记录
      let data = {
        activityId: this.activity.id,
        viewerId: this.userInfo.id
      }
      groupOnApi.addViewer(data).then(res => {
        if (res.succ) {
          console.log('添加成功')
        }
      })
    },
    initWxConfig() {
      // 配置微信信息
      if (isOnline()) {
        wxconfig([this.initShareData])
      }
    },
    initShareData() {
      // 配置分享信息
      let userInfoId = this.userInfo.id
      // 此页面使用微信分享时，没有团队信息
      let link = process.env.VUE_APP_BASE_URL + `/${this.appId}/groupOn/detail/${this.activity.id}/-1?ruId=${userInfoId}`
      let miniCover = `http://img.cover.c60rock.com/market_activity_${this.activity.id}.jpg`
      let shareOptions = {
        title: this.activity.title,
        desc: this.activity.descn,
        link: link,
        imgUrl: miniCover
      }
      // 分享给好友
      this.$wx.updateAppMessageShareData(shareOptions)
      // 分享到朋友圈
      this.$wx.updateTimelineShareData(shareOptions)
    }
  }
}
</script>
<style lang="less" scoped>
.activity {
  position: relative;
  background-color: #fff;
}

.tips {
  font-size: 0.36rem;
  font-weight: 600;
  color: darkred;
  padding: 0.2rem;
  border: 1px dashed darkred;
  margin-bottom: 0.2rem;

  ul {
    li {
      line-height: 0.55rem;
    }
  }
}
.my-swipe {
  height: 280px;
  img {
    width: 100%;
    height: 280px;
  }
}
.activity_info {
  padding: 0.1rem 0.2rem;
  position: relative;
  font-size: 0.36rem;
  .title {
    font-size: 22px;
    min-height: 28px;
    line-height: 28px;
    font-weight: normal;
  }
  .descn {
    margin: 6px 0;
    font-size: 14px;
    color: #888;
  }
  .price {
    margin: 6px 0;
    .orig_price {
      color: darkred;
      font-size: 20px;
      font-weight: bold;
    }
    .line_price {
      padding-left: 10px;
      color: #aaa;
      font-size: 12px;
      text-decoration: line-through;
    }
  }
  .item {
    height: 0.8rem;
    line-height: 0.8rem;
    .label {
      width: 1rem;
    }
    .value {
      color: #999;
      width: 1rem;
      text-align: left;
    }
  }
}
.activity-content {
  /deep/ p {
    margin: 0 auto;
    img {
      display: block;
    }
  }
}
.ext-data-form-container {
  margin: -0.2rem 0.2rem 0.2rem 0.2rem;
  border: 1px dashed darkred;
}

.teams {
  min-height: 1.2rem;
  padding: 0.1rem 0.2rem;
  font-size: 0.36rem;
  .title {
    border-left: 0.2rem solid darkred;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
    font-weight: bold;
  }
  .avatar-queue {
    border: 1px dashed darkred;
    margin: 0.2rem 0;
    padding: 0.2rem 0 0.2rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    .avatar {
      margin-bottom: 0.2rem;
      img {
        width: 1rem;
        height: 1rem;
        display: block;
        border: 1px solid #aaa;
        border-radius: 50%;
        margin-left: -14px;
      }
    }
  }
}
.team-members {
  min-height: 100px;
  padding: 0.2rem;
  font-size: 0.36rem;
  .avatar-queue {
    margin: 0.2rem 0;
    img {
      width: 1rem;
      height: 1rem;
      display: block;
      border: 1px solid #aaa;
      border-radius: 50%;
      margin-left: -14px;
    }
  }
  .title {
    border-left: 0.2rem solid darkred;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
    font-weight: bold;
  }
  .van-grid {
    margin-top: 0.2rem;
    border: 1px dashed darkred;
    min-height: 1.6rem;
    /deep/ .van-badge--fixed {
      transform: translate(25%, 25%);
    }
    img.avatar {
      border: 1px solid #999;
      border-radius: 50%;
      width: 1.4rem;
      height: 1.4rem;
    }
  }
}

.buyer-wrapper {
  min-height: 1rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  overflow: auto;
  padding-left: 0.56rem;
  padding-right: 0.2rem;
  font-size: 0.36rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  .title {
    margin-left: -14px;
    margin-right: 0.4rem;
    border-left: 0.2rem solid darkred;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
    font-weight: bold;
  }
  .avatar-queue {
    margin: 0.2rem 0;
    img {
      width: 1rem;
      height: 1rem;
      display: block;
      border: 1px solid #aaa;
      border-radius: 50%;
      margin-left: -14px;
    }
  }
}

.referee-wrapper {
  height: 40px;
  line-height: 40px;
  margin-bottom: 10px;
  display: flex;
  .title {
    width: 70px;
    font-size: 14px;
    height: 40px;
    font-weight: bold;
    line-height: 40px;
  }
  .referee-info {
    flex: 1;
    display: flex;
    .avatar-box {
      width: 40px;
      img {
        width: 36px;
        border: 1px solid #aaa;
        border-radius: 50%;
      }
    }
    .nickname {
      flex: 1;
      text-align: left;
      padding-left: 10px;
      color: #aaa;
    }
  }
}
.van-goods-action-button--first,
.van-goods-action-button--last {
  border-radius: 0;
}
.copyright {
  display: block;
  height: 1.2rem;
  line-height: 1.2rem;
  text-align: center;
}
</style>
